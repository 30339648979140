import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Alert from "@mui/material/Alert";
import { MdOutlineDoneOutline } from "react-icons/md";
import check from "../../assets/icons/img/check.gif";
import img2 from "../../assets/icons/img/cloud-lord.gif";
import imgBlack from "../../assets/icons/img/cloud-lord-black.gif";
import axios from "axios";
import { ep, common } from "../../config/dev";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import errorImg from "../../assets/icons/img/error.gif";
import AppContext from "../../contexts/AppContext";
import { useLocation } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./Dropzone.css";
import { api_post } from "../../services/service";

function Dropzone() {
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [customColor, setCustomColor] = useState("");

  const { resumeResponse, setResumeResponse } = useContext(AppContext);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/about") {
      setCustomColor("white");
    } else {
      setCustomColor("");
    }
  }, [location.pathname]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    onDrop: (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];

      const formData = new FormData();
      formData.append("resumes", selectedFile);
      formData.append("source", "tiq-website");

      if (acceptedFiles && acceptedFiles.length > 0) {
        let shortName;

        shortName = acceptedFiles[0].name;
        if (shortName.length > 10) {
          shortName = `${shortName.slice(0, 7)} ... ${shortName
            .split(".")
            .pop()}`;
          setUploadedFileName(shortName);
        } else {
          setUploadedFileName(acceptedFiles[0].name);
        }
        setIsFileUploaded(true);

        const newTimer = setInterval(() => {
          setProgress((prevProgress) => {
            const newProgress = prevProgress >= 100 ? 0 : prevProgress + 10;

            if (newProgress === 100) {
              clearInterval(newTimer);
            }
            return newProgress;
          });
        }, 800);

        setTimer(newTimer);

        axios
          .post(
            ep.resumeparsing + "/latest/v1/resumeparsingrequest/file/parse",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                service_ref: common.service_ref,
              },
              // onUploadProgress: (progressEvent) => {
              //   const progress = Math.round(
              //     (progressEvent.loaded / progressEvent.total) * 100
              //   );
              //   setUploadProgress(progress);
              // },
            }
          )
          .then((response) => {

           console.log('response.data',response.data);

            if (response.data.data.success === false) {
              // console.log(response.data.success);
              setResumeResponse(false);
            }
            const _candidate_guid = response.data.data.candidate?.guid;
            const requestBody = {
              candidate: {
                  full_name: response.data.data?.candidate?.full_name,
                  email_id: response.data.data?.candidate?.email_id,
              },
            };

            setUploading(false);
            setUploadProgress(0);
            api_post(null, ep.assessment + "/v1/candidate/" + _candidate_guid, requestBody, (error, responseData) => {
            // api_post(null, ep.assessment + "/v1/candidate/can-14d8489f-df68-479f-a054-072d41e3b06e" , requestBody, (error, responseData) => {
              if (!error) {
                window.location.href = `${ep.webapp}/assessmentsession-stepuserprofile?candidate_guid=${_candidate_guid}`;
                // window.location.href = `http://localhost:3001//assessmentsession-stepuserprofile?candidate_guid=can-14d8489f-df68-479f-a054-072d41e3b06e`;
              }
            });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            setResumeResponse(false);
            // console.log(resumeResponse);
            setUploading(false);
            setUploadProgress(0);
          });
      }
    },
  });

  let textToDisplay;
  if (isFileUploaded && progress !== 100) {
    textToDisplay = uploadedFileName;
  } else if (isFileUploaded && progress === 100) {
    textToDisplay = "";
  } else {
    textToDisplay = "Drag and drop a file here";
  }

  let iconToDisplay;
  if (isFileUploaded && progress === 100 && resumeResponse !== false) {
    iconToDisplay = check; // changed from img1 to check
  } else if (isFileUploaded && progress === 100 && resumeResponse === false) {
    iconToDisplay = errorImg;
  } else if (!isFileUploaded && customColor) {
    iconToDisplay = imgBlack;
  } else {
    iconToDisplay = img2;
  }

  let styleObject;
  if (iconToDisplay === check || iconToDisplay === errorImg) {
    styleObject = { width: "48px", height: "48px", marginTop: "15px" };
  } else {
    styleObject = { width: "40px", height: "40px" };
  }

  let messages = [
    "Please wait while we process your resume",
    "Analyzing your work experience to match with job requirements",
    "Verifying the accuracy of your education details and qualifications",
    "Processing your certifications and professional achievements",
    "Almost done...",
    "Thank you for your patience!",
  ];

  let messageToDisplay;
  let alertStyle = "success";

  if (resumeResponse !== false) {
    if (progress <= 20) {
      messageToDisplay = messages[0];
    } else if (progress > 20 && progress <= 40) {
      messageToDisplay = messages[1];
    } else if (progress > 40 && progress <= 60) {
      messageToDisplay = messages[2];
    } else if (progress > 60 && progress <= 80) {
      messageToDisplay = messages[3];
    } else if (progress > 80 && progress < 100) {
      messageToDisplay = messages[4];
    } else {
      messageToDisplay = messages[5];
    }
  } else {
    messageToDisplay = "Something went wrong!";
    alertStyle = "error";
  }

  const theme = createTheme({
    palette: {
      ochre: {
        main: "#E3D026",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#242105",
      },
    },
  });

  return (
    <>
      <div
        className="resume-box shadow-lg"
        {...getRootProps()}
        style={customColor ? { backgroundColor: customColor } : {}}
      >
        <input {...getInputProps()} />

        <span>
          {isFileUploaded && progress !== 100 ? (
            <MdOutlineDoneOutline style={{ color: "white" }} />
          ) : (
            <img src={iconToDisplay} style={styleObject} alt="img" />
          )}
        </span>
        <strong>
          <span
            className="drag-drop-text mt-2"
            style={customColor ? { color: "black" } : {}}
          >
            {textToDisplay}
          </span>
        </strong>
        <div className="progress">
          {" "}
          {isFileUploaded && progress !== 100 && (
            <>
              {/* <ThemeProvider theme={theme}> */}
              <LinearProgress
                color="secondary"
                // style={{ backgroundColor: "#00ff00" }}
                // sx={{ color: "rgb(0, 255, 0)" }}
                variant="determinate"
                value={progress}
              />
              {/* </ThemeProvider> */}
              <Typography
                variant="body2"
                style={{ color: "white", marginLeft: 8 }}
              >
                {progress}%
              </Typography>
            </>
          )}
        </div>
      </div>
      <div className="mt-2">
        {isFileUploaded && (
          <Alert severity={alertStyle}>{messageToDisplay}</Alert>
        )}
      </div>
    </>
  );
}

export default Dropzone;
